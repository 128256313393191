<template>
	<main class="main">
		<div class="main-slider">
      <div class="container">
        <Crumbs :whiteText="true" :data="crumbs"></Crumbs>
      </div>
			<div class="main-slider__slide">
				<div class="main-slider__container container">
					<div class="main-slider__content">
						<h1 class="main-slider__title">Материалы для партнеров</h1>
						<p class="main-slider__text">Ознакомиться можно ниже</p>
						<img
							:src="require('@/assets/images/icons/icon_arrow-down.svg')"
							alt="Стрелка вниз"
						/>
					</div>

					<div class="main-slider__image-wrapper">
						<img
							:src="
								require('@/assets/images/background/bg_pattern-materials-partner.svg')
							"
							alt="Фоновое изображение"
							class="main-slider__pattern"
						/>
						<img
							:src="
								require('@/assets/images/pictures/image-materials-partner.png')
							"
							alt="Фоновое изображение"
							class="main-slider__image"
						/>
					</div>
				</div>
			</div>
		</div>

		<div class="bg bg--gray">
			<section class="container">
				<Documents :data="documents"></Documents>
			</section>
		</div>

		<section class="container" v-if="blocks">
			<div class="main__box">
				<Info :data="blocks"></Info>
			</div>
		</section>
	</main>
</template>

<script>
import Documents from '@/components/Documents.vue'
import Info from '@/components/Info.vue'
import Crumbs from '@/components/Crumbs'

export default {
	name: 'PartnerMaterials',

	components: {
		Documents,
		Info,
		Crumbs,
	},

	computed: {
		documents() {
			return {
				title: 'Документы',
				type: 'basic',
				crumbs: null,
			}
		},

		blocks() {
			const blocks = this.$store.getters.BLOCKS

			return blocks[this.$options.name]
		},
	},

	mounted() {
		this.$store.dispatch('GET_BLOCKS', {
			page: this.$route.fullPath,
			block: this.$options.name,
		})
    this.crumbs = [
      {
        link: 'PartnerHome',
        text: 'Главная',
      },
      {
        link: null,
        text: 'Материалы',
      },
    ]
	},
}
</script>
